import { PutObjectCommandOutput, S3 } from "@aws-sdk/client-s3";

export async function uploadToS3(
  file: File
): Promise<{ file_key: string; file_name: string }> {
  return new Promise((resolve, reject) => {
    try {
      const s3 = new S3({
        region: "eu-central-1",
        credentials: {
            accessKeyId: "AKIATOAK27XXED5DTRX2",
            secretAccessKey: "rES/USrcHi382mgtFGCMdsB3V33FR7n+lalocxxs",
        },
      });

      const file_key =
        "uploads/" + Date.now().toString() + file.name.replace(" ", "-");

      const params = {
        Bucket: "pdfchatai",
        Key: file_key,
        Body: file,
      };
      s3.putObject(
        params,
        (err: any, data: PutObjectCommandOutput | undefined) => {
          return resolve({
            file_key,
            file_name: file.name,
          });
        }
      );
    } catch (error) {
      reject(error);
    }
  });
}

export function getS3Url(file_key: string) {
  const url = `https://pdfchatai.s3.ap-southeast-1.amazonaws.com/${file_key}`;
  return url;
}
