import React, { useState,useEffect,useRef } from 'react';
import './Sidebar.css';
import { Auth } from './Auth';
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { Upload } from "@aws-sdk/lib-storage";
import { S3Client, S3 } from "@aws-sdk/client-s3";
import { FileUp, FileX } from 'lucide-react';
import { Loader } from 'lucide-react';
import AWS from "aws-sdk";
import { uploadToS3 } from "./s3/s3";
import { toast } from "react-hot-toast";
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, googleProvider } from '../firebase';
import GoogleModal from './GoogleModal';
import bgImage from './bg.jpg';
import CommonButton from './CommonButton';
import CommonButton2 from './CommonButton2';
import jsPDF from 'jspdf';
import Loading from './LoadingAnimation';
import { signInWithPopup } from "firebase/auth";
import PremiumButtonWithIcon from './PremiumButton';
import AlreadyPremiumButton from './AlreadyPremiumButton';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function Sidebar() {
  const [textAreaValue, setTextAreaValue] = useState('');
  const [selectedOption, setSelectedOption] = useState('mcq');
  const [selectedOptionDifficulty, setSelectedOptionDifficulty] = useState('easy'); // Add state for difficulty
  const [selectedOptionQuestionNum, setSelectedOptionQuestionNum] = useState('1');
  const [selectedOptionType, setSelectedOptionType] = useState('3');
  const [fetchData, setData] = useState("");
  const [user] = useAuthState(auth);
  const [showModal, setShowModal] = useState(false);
  const [targetfileName, settargetfileName] = useState('');
  const [isSubscribed,setSubscribed]=useState(null);
  const [loading,setLoading]=useState(false);
  const runs = useRef(null);
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [isSmallScreen, setSmallScreen] = useState(window.innerWidth < 768); // Adjust the breakpoint as needed
  
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedText, setEditedText] = useState("");



  
  const handleTextChange = (e: React.ChangeEvent<HTMLDivElement>) => {
    const selection = window.getSelection();
    if (selection) {
      const range = selection.getRangeAt(0);
      const br = document.createElement('br');
      range.deleteContents();
      range.insertNode(br);
      range.setStartAfter(br);
      range.setEndAfter(br);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  
    // Update the edited text as the user types
    setEditedText(e.currentTarget.innerText);
  };
  
  

  const handleSave = () => {
    // Save the edited text when the user finishes editing
    setData(editedText);
    exitEditMode();
  };

  const enterEditMode = () => {
    // Set the edited text to the current content
    setEditedText(fetchData);
    setIsEditMode(true);
  };

  const exitEditMode = () => {
    setIsEditMode(false);
  };

  useEffect(() => {
    // Update editedText when fetchData changes
    setEditedText(fetchData);
  }, [fetchData]);


  useEffect(() => {
    const handleResize = () => {
      setSmallScreen(window.innerWidth < 768); // Adjust the breakpoint as needed
    };

    // Set the initial value
    setSmallScreen(window.innerWidth < 768);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setSmallScreen(window.innerWidth < 768); // Adjust the breakpoint as needed
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextAreaValue(e.target.value);
  };
  
  const handleOptionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(e.target.value);
  };

  
  const handleOptionChangeType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOptionType(e.target.value);
  };

  const handleOptionChangeQuestionNum = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOptionQuestionNum(e.target.value);
  };

  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
  const handleCheckUser = async () => {
    try {
      const response = await fetch('https://api.v1.quizoon.com/check_user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_email: auth?.currentUser?.email }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      console.log(result)
      setSubscribed(result.user_exists);
    } catch (error) {
      console.error('Error checking user:', error);
    }
  };
  if (auth?.currentUser?.email) {
    handleCheckUser();
    after_first_auth();
    get_remaining_runs();
    
  }
}, [auth?.currentUser?.email]);

  const handleOptionChangeDifficulty = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOptionDifficulty(e.target.value);
  };
  const exportToTxt = (fetchData: string) => {
    const element = document.createElement('a');
    const file = new Blob([fetchData], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = 'questions.txt';
    document.body.appendChild(element);
    element.click();
  };
  
  const exportToPdf = (fetchData: string) => {
    const doc = new jsPDF();
    let yOffset = 10;
    let page = 1;
    let fullText = fetchData; // Use fetchData directly as the full text
    let fontSize = 11; // Initial font size

    while (doc.getTextDimensions(fullText, { fontSize }).h > 280 - yOffset) {
        fontSize -= 1; // Decrease font size until the text fits
    }

    const textLines = doc.splitTextToSize(fullText, 180, { fontSize });
    const requiredSpace = textLines.length * (fontSize * 1.5);

    if (yOffset + requiredSpace > 280 && page !== 1) {
        doc.addPage();
        page += 1;
        yOffset = 10;
    }

     // Set the font type to support UTF-8 characters
    doc.setFontSize(fontSize); // Set the adjusted font size
    doc.text(textLines, 10, yOffset);
    yOffset += requiredSpace; // Update the yOffset
    doc.save(`formattedQuestions_page${page}.pdf`);
};

const handleButtonClick = async () => {
  setLoading(true);

  const requestData = {
    text: textAreaValue,
   
  };

  console.log(requestData);

  try {
    const response = await fetch('https://api.v1.quizoon.com/similar_quiz', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    console.log('Response from the API:', data);
    setData(data.result);
  
  }
   catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    setLoading(false);
    console.log(loading); // Move console.log(loading) inside the finally block
  }
};


const decrement_run_fremium = async () => {
  try {
    const response = await fetch('https://api.v1.quizoon.com/decrement_runs', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user_email: auth?.currentUser?.email })
    });

    const data = await response.json();
    console.log('decrement_run_fremium successful:', data);
  } catch (error) {
    console.error('Error in decrement_run_fremium:', error);
  }
};

const get_remaining_runs = async () => {
  try {
    const response = await fetch('https://api.v1.quizoon.com/get_remaining_runs', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user_email: auth?.currentUser?.email })
    });

    const data = await response.json();
    console.log('get_remaining_runs successful:', data);

    if (data.remaining_runs !== undefined) {
      runs.current=(data.remaining_runs);
    }
  } catch (error) {
    console.error('Error in get_remaining_runs:', error);
  }
};


const after_first_auth = async () => {
  try {
    const response = await fetch('https://api.v1.quizoon.com/after_auth', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user_email: auth?.currentUser?.email })
    });

    const data = await response.text();
    console.log('Response from after_first_auth:', data); // Log the response

    // Now, attempt to parse the response as JSON
    const jsonData = JSON.parse(data);
    console.log('Parsed JSON data:', jsonData);
  } catch (error) {
    console.error('Error in after_first_auth:', error);
  }
};

  
const downloadRequest = async (fileName:string) => {
  setLoading(true)
  const requestData = {
    
    fileName: fileName,
};
console.log(requestData);
  try {
    const response = await fetch('https://api.v1.quizoon.com/download_s3_for_similar_quiz', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
  
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();

    // Handle the data received from the API
    console.log('Response from the API:', data);
    setData(data.result); // Make sure you have access to the setData function
   
    // setData(formattedResponse); // Make sure you have access to the setData function
  } catch (error) {
    // Handle any errors
    console.error('Error fetching data:', error);
  }
  setLoading(false)
};

const FileUpload = () => {
  const [uploading, setUploading] = React.useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: { "application/pdf": [".pdf"] ,"application/txt": [".txt"],"application/doc": [".doc"],"application/docx": [".docx"]},
    maxFiles: 1,
    onDrop: async (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file.size > 10 * 1024 * 1024) {
        // bigger than 10mb!
        toast.error("File too large");
        return;
      }

      try {
        setUploading(true);
        const data = await uploadToS3(file);
        const filename = data.file_key.substring(data.file_key.lastIndexOf('/') + 1);
        console.log(file.name);
        settargetfileName(filename);
        console.log("meow", data);
        if (!data?.file_key || !data.file_name) {
          toast.error("Something went wrong");
          return;
        }
      
      } catch (error) {
        console.log(error);
      } finally {
        setUploading(false);
      }
    },
  });
  return (
    <div className="p-2 bg-white rounded-xl">
      <div
        {...getRootProps({
          className:
            "border-dashed border-2 rounded-xl cursor-pointer bg-gray-50 py-8 flex justify-center items-center flex-col",
        })}
      >
        {auth?.currentUser ? (
          <>
            <input {...getInputProps()} />
            {uploading ? (
              <>
                {/* loading state */}
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Loader className="h-10 w-10 text-blue-500 animate-spin" />
                  <p className="mt-2 text-sm text-slate-400">Uploading...</p>
                </div>
              </>
            ) : (
              <>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <FileUp className="w-100 h-100 text-blue-500" />
                  <p className="mt-2 text-sm text-slate-400">Upload any TXT/PDF/WORD </p>
                </div>
              </>
            )}
          </>
        ):
        ( <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }  }  onClick={() => {
      signInWithGoogle()
    }}>
            <FileUp className="w-100 h-100 text-blue-500" />
            <p className="mt-2 text-sm text-slate-400">Upload any TXT/PDF </p>
          </div>
        </>)
         }
      </div>
    </div>
  );
            }

  const insertLineBreaks = (str: string, limit: number) => {
    const regex = new RegExp(`(.{${limit}})`, 'g');
    return str.replace(regex, '$1\n');
  };
  

  function EditWithQuill() {
    const quillRef = useRef<ReactQuill | null>(null);

    const handleSaveQuill = () => {
      const editor = quillRef.current?.getEditor();
      if (editor) {
        const editorContent = editor.getContents();
        const textContent = editorContent.ops
          ? editorContent.ops
              .map((op: any) => (typeof op.insert === 'string' ? op.insert : ''))
              .join('')
          : '';
        
        // Use textContent as needed
        setData(textContent);
        console.log(textContent);
      }
    };
  
    const [value, setValue] = useState('');
    
  
    return(
      <div
     
  style={{
    whiteSpace: 'pre-line',
    backgroundColor: '#fbebbb',
    borderRadius: 10,
    margin: 30,
    minHeight: 100,  // Set a minimum height to avoid collapsing
    outline: 'none', // Remove the outline when in edit mode
    cursor: 'text',  // Set the cursor to text when in edit mode
    border: '1px solid #ccc',  // Add a border for better visibility
  }}>
    <button onClick={handleSaveQuill}>Save Changes</button>
    <ReactQuill theme="snow" ref={quillRef} value={fetchData}  />;
    
    </div>
    )
  }

  const options = [];
  for (let i = 1; i <= 25; i++) {
    options.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }
  const divStyle = {
    backgroundImage: `url(${bgImage})`,
    backgroundSize: 'cover',
    minHeight: '100vh',

  };
  
  const mediaQueryStyle = {
    '@media (max-width: 768px)': {
      backgroundSize: 'auto',
    },
  };
  
  return (
    
    <div style={{ ...divStyle, ...mediaQueryStyle }}>

{!isSubscribed && runs.current != null && (
  <div className='runs'>
    <p style={{ fontSize: 16 }}>Free Runs Remaining: {runs.current}</p>
  </div>
)}

<div className='auth-style'>
  <Auth /> 
  </div>
  
<div className={`page-container ${isSmallScreen ? 'small-screen' : ''}`} >
  
        <div className={`sidebar-container ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`} >
          <div className="sidebar" >
          {auth?.currentUser && 
<div style={{ margin:15,flex:1,flexDirection:'column',justifyContent:'center' }}>
  {!isSubscribed  ? (   
   
      <PremiumButtonWithIcon />
      

)         :
(<AlreadyPremiumButton/>)}
    </div>
}       
   
      <div className="menu" style={{height:"60rem"}}>
        <a className="menu-item" href="/start">
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 20 20"
            aria-hidden="true"
            height="26"
            width="26"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
          </svg>
          <p className="truncate" style={{fontWeight:'bold'}}>Home</p>
        </a>
       
        <a className="menu-item" href="/start_similar">
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 24 24"
            height="26"
            width="26"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path d="M5.455 15L1 18.5V3a1 1 0 0 1 1-1h15a1 1 0 0 1 1 1v12H5.455zm-.692-2H16V4H3v10.385L4.763 13zM8 17h10.237L20 18.385V8h1a1 1 0 0 1 1 1v13.5L17.545 19H9a1 1 0 0 1-1-1v-1z"></path>
            </g>
          </svg>
          <p className="truncate" style={{fontWeight:'bold'}}>Similar Quiz</p>
        </a>
      </div>

      
    </div>
    {isSmallScreen && (
            <div className="toggle-sidebar-btn" onClick={toggleSidebar}>
              Toggle Sidebar
            </div>
          )}
       
     </div>
 
    <div className='middle' >
    <div className="header">
        <h1 className="bold-big-header">Generate similar questions to expand your question bank!</h1>
       
      </div>
      <FileUpload/>
        <div style={{ textAlign: "center" }}>
             <p>or</p>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

        <p>Enter  you want to create questions from</p>
        </div>
      <textarea
          style={{backgroundColor:'white'}}
          rows={5}
          cols={80}
          value={textAreaValue}
          onChange={handleTextAreaChange}
        />
        <div >
             
            </div>
            
            
            <>
      {user ? (
        <div className='my-custom-div'>
        <>
        {!(textAreaValue === '') && (
  loading ? (
    <Loading />
  ) : (
    <CommonButton2
    onClick={() => {
      handleButtonClick();
      {isSubscribed == false &&
      decrement_run_fremium();
      }
    }}
    variant="contained"
    color="success"
    disableRipple
    disabled={runs.current !== null && runs.current <= 0 && isSubscribed == false}
    >
    Create questions from text
  </CommonButton2>
  
  )
)}

        <div style={{padding:30}}>
          
        </div>
        {!(targetfileName === '') &&
        (
          loading ? (
            <Loading />
          ) : (
        <CommonButton onClick={() => downloadRequest(targetfileName)}  variant="contained"   disableRipple     disabled={runs.current !== null && runs.current <= 0}
        >
          Create questions from file
        </CommonButton>)
            )}
        </>
        </div>
       
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',paddingTop:30}}>
      <CommonButton onClick={openModal}  variant="contained" disableRipple> Login/SignUp</CommonButton>
      <GoogleModal showModal={showModal} hideModal={closeModal} />
    </div>
      )}
    </>
  
    {fetchData && (
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
    <CommonButton variant="contained" disableRipple onClick={() => exportToTxt(fetchData)}>Export as TXT</CommonButton>
    <div style={{ display: 'flex', flexDirection: 'row', width: 20 }}></div>
    <CommonButton variant="contained" disableRipple onClick={() => exportToPdf(fetchData)}>Export as PDF</CommonButton>
  </div>
)}
 
 {fetchData && (
        <div>
         <EditWithQuill/>
        </div>
      )}
      </div>

<div>

</div>



{/* Condition 2: Render free runs remaining if subscribed and runs.current is not null */}



     
    </div>
  

   </div>
  );
}


