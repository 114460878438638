import { lazy, Suspense } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import routes from "./config";
import { Styles } from "../styles/styles";
import Sidebar from "../main/Main";
import Blooms from "src/main/Blooms";
import Similar from "src/main/Similar";
const Router = () => {
  const location = useLocation();

  // Check if the current route is "/start"
  const isStartRoute =
  location.pathname === "/start" ||
  location.pathname === "/start_bloom" ||
  location.pathname === "/start_similar";

  return (
    <Suspense fallback={null}>
      <Styles />
      {/* Conditionally render Header if not on the /start route */}
      {!isStartRoute && <Header />}
      <Switch>
        {routes.map((routeItem) => {
          return (
            <Route
              key={routeItem.component}
              path={routeItem.path}
              exact={routeItem.exact}
              component={lazy(() => import(`../pages/${routeItem.component}`))}
            />
          );
        })}
        <Route path="/start">
          <Sidebar />
        </Route>
       
        <Route path="/start_similar">
          <Similar />
        </Route>
      </Switch>
      {/* Conditionally render Footer if not on the /start route */}
      {!isStartRoute && <Footer />}
    </Suspense>
  );
};

export default Router;
