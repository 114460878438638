import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import StarIcon from '@mui/icons-material/Star';

const PremiumButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 18,
  padding: '10px 20px',
  border: '1px solid',
  borderRadius: 8,
  lineHeight: 1.5,
  backgroundColor: '#4caf50', // Greenish color
  borderColor: '#4caf50',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '&:hover': {
    backgroundColor: '#45a049', // Lighter greenish color on hover
    borderColor: '#45a049',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#45a049', // Slightly darker greenish color on active
    borderColor: '#4caf50',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(76,175,80,.5)', // Focus color
  },
});

const PremiumButtonWithIcon = () => (
  <PremiumButton startIcon={<StarIcon />}>
    <a
      href="https://quizoon.lemonsqueezy.com/checkout/buy/5523e925-5a1c-45e1-9365-a65f603c8e4d?embed=1"
      style={{
        marginLeft: '8px', // Adjust the margin as needed
        color: 'white', // Text color
        textDecoration: 'none', // Remove underline
      }}
    >
      Upgrade
    </a>
    <script src="https://assets.lemonsqueezy.com/lemon.js" defer></script>
  </PremiumButton>
);

export default PremiumButtonWithIcon;
