import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDMYuHQ70W9rUD_Jx-Vag9ndQJXoeKS4v0",
  authDomain: "webapp-4a91a.firebaseapp.com",
  projectId: "webapp-4a91a",
  storageBucket: "webapp-4a91a.appspot.com",
  messagingSenderId: "812666496474",
  appId: "1:812666496474:web:ea80e6f09b89f20e840ce3",
  measurementId: "G-H9SRS4ME3F"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const db = getFirestore(app);
