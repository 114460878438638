import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';

const AlreadyPremiumButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 18,
  padding: '10px 20px',
  border: '1px solid',
  borderRadius: 8,
  lineHeight: 1.5,
  backgroundColor: '#ffd700', // Yellowish gold color
  borderColor: '#ffd700',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '&:hover': {
    backgroundColor: '#ffdb58', // Lighter yellowish gold color on hover
    borderColor: '#ffdb58',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#ffdb58', // Lighter yellowish gold color on active
    borderColor: '#ffdb58',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(255,215,0,.5)', // Focus color
  },
});

const PremiumButtonWithIcon = () => (
  <AlreadyPremiumButton startIcon={<WorkspacePremiumIcon />}>
    Unlimited
  </AlreadyPremiumButton>
);

export default PremiumButtonWithIcon;
