import React from "react";
import { signInWithPopup } from "firebase/auth";
import { auth, googleProvider } from '../firebase';
import Google from './google.png';
import { XCircle } from 'lucide-react';
type SignInModalProps = {
  showModal: boolean;
  hideModal: () => void;
};

const SignInModal: React.FC<SignInModalProps> = ({ showModal, hideModal }) => {
  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div
      style={{
        display: showModal ? "flex" : "none",
        position: "fixed",
        zIndex: 1,
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0,0,0,0.4)",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "5px",
        }}
      >
      <div
          onClick={signInWithGoogle}
          style={{ cursor: "pointer" }}
        >
          <img src={Google} style={{ width: "280px", height: "70px" }} alt="Google logo" />
        </div>
        <div onClick={hideModal} style={{ display: "flex", justifyContent: "center",paddingTop:20}}
>
            <XCircle  size={24}/>
        </div>
      </div>
    </div>
  );
};

export default SignInModal;
