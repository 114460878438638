import { auth, googleProvider } from '../firebase';
import { createUserWithEmailAndPassword,signInWithPopup, signOut } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth';
import CommonButton from './CommonButton';
export const Auth = () => {
  
    console.log(auth?.currentUser?.email);
const [user] = useAuthState(auth);

  
  const signInWithGoogle = async () => {
    try {
    await signInWithPopup(auth,googleProvider);
    } catch (err){
      console.error(err);
    }
  };
  const logOut = async () => {
    try {
    await signOut(auth);
    } catch (err){
      console.error(err);
    }
  };
  return (
    <div className="top-right-buttons">
     
      <>
      {user ? (
        <>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center',paddingRight:10}}>
          <label style={{display: 'flex', flexDirection: 'column', alignItems: 'center',paddingRight:10,paddingBottom:10}}>Hello, {user?.displayName}</label>
          <CommonButton  onClick={logOut} variant="contained" disableRipple>Log Out</CommonButton>
        </div>
        </>
      ) : (
        <div>
        <CommonButton  onClick={signInWithGoogle} variant="contained" disableRipple>Sign In with Google</CommonButton>
        </div>
      )}
    </>
    </div>
  );
};